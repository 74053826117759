/* Semantic UI Overrides */

form.ui.form {
  padding-bottom: 1em;
}

.form-group.field {
  display: flex;
  flex-direction: column;
}

.ui.form .field > .selection.dropdown {
  height: 100%;
}

.ui.active.visible.search.selection.dropdown {
  overflow: visible;
}

form.ui.form label {
  line-height: 2;
}

.ui.checkbox {
  float: right;
}

.ui.checkbox label {
  line-height: 1 !important;
}

/* Draft.js Wrapper */

#note-editor-wrapper {
  display: block;
  box-sizing: border-box;
}

div.DraftEditor-root {
  box-sizing: border-box;
  padding: 14px 14px;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
  height: calc(50vh);
  width: 100%;
  font-weight: 500;
  resize: vertical;
  overflow-y: auto;
}
div.DraftEditor-editorContainer,
div.public-DraftEditor-content {
  height: 100%;
}

/* Editor toolbar */

div.editor-toolbar {
  margin-bottom: 0.5em;
}

div.editor-toolbar > *:not(:first-child) {
  margin-left: 8px !important;
}

#last-edited-text {
  margin-top: 8px;
}
