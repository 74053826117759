.react-datepicker-wrapper {
  width: 100%;
}

.datePicker {
  width: 100%;
  height: 46px;
  border: 1px solid #8b9199;
  border-radius: 4px;
  box-sizing: border-box;
  overflow: hidden;
  display: inline-flex;
  position: relative;
  padding: 0 9px 0 9px;
  font-family: 'HKGrotesk';
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.3px;
  color: #0a162a;
}
