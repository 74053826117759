nav {
  display: flex;
  margin: 0 auto;
  align-items: stretch;
  position: relative;
  height: 70px;
  --font-size: 16px;
}

nav::after {
  content: '';
  width: 100vw;
  position: absolute;
  bottom: 0;
  left: min(calc((100vw - 1200px) / -2), -2.5%);
}

nav #nav-title {
  text-align: left;
  margin: 0;
  font-size: 18px;
  /* padding: 1rem 0rem 0rem; */
  flex-grow: 1;
  display: flex;
  align-items: center;
  font-weight: 500;
}

nav .nav-link {
  text-decoration: none;
  color: black;
}

nav .nav-link .nav-brand {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
}

nav .nav-link .nav-brand img {
  margin-right: 12px;
  height: '90%';
}

nav .profile-item p {
  margin: 0;
  display: flex;
  margin-left: auto;
  align-items: center;
}

nav .profile-item .dropdown > p > *:last-child {
  margin-left: 1em;
}

nav > ul {
  padding: 0;
  margin: 0;
  display: flex;
  align-items: stretch;
  font-size: var(--font-size);
}

nav > ul > li {
  display: flex;
}

nav > ul > li > * {
  padding: 0 1.5em;
  display: flex;
  align-items: center;
}

nav > ul > li > a.active {
  color: #0069ca;
  font-weight: 700;
}

nav > ul > li > a {
  color: #333333;
}
