.attribute {
  margin-bottom: 1rem;
}

.profile-alert {
  margin: 3rem;
}

#submit-button {
  margin: 2rem auto 0;
  display: block;
}
