.dropdown {
  overflow: visible;
  position: relative;
  padding-right: 0;
}

.dropdown:hover {
  cursor: pointer;
}

.dropdown .avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.5em;
  height: 2.5em;
  border-radius: 50%;
  background: navy;
  color: white;
  user-select: none;
  padding: 0px !important;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  right: 0;
  top: calc(100% - 1px);
  overflow: hidden;
  border-radius: 6px !important;
}

.dropdown-item {
  float: none;
  display: block;
  padding: 12px 28px 12px 28px;
  width: 100%;
  border: none;
  color: black;
  text-decoration: none;
  text-align: left;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  background-color: white;
}

.dropdown-content .dropdown-item:hover {
  background-color: #ddd;
  cursor: pointer;
}

.dropdown:hover .dropdown-content {
  display: block;
}
